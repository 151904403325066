import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  VStack,
  Input,
  Button,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import axios from "axios";

const ReqDemoModal = ({ isOpen, onClose }) => {
  const { colorMode } = useColorMode();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!name || !email || !contactNumber) {
      toast({
        title: "Error",
        description: "Please fill out all required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true); // Disable the submit button

    try {
      const formData = new FormData();
      formData.append("Name", name);
      formData.append("Email", email);
      formData.append("Mobile", contactNumber);

      await axios.post(
        "https://script.google.com/macros/s/AKfycbyQ0ZXvxtid26fxYhrcSaQyyXuba7bLryiahg1euFT5u5s5qZ5ZdcEKGkfo-9LQre8/exec",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setName("");
      setEmail("");
      setContactNumber("");
      setSubmitted(false);

      toast({
        title: "Form Submitted",
        description: "We will get back to you soon.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error("Form submission error:", error);
      toast({
        title: "Form Submission Failed",
        description: "An error occurred while submitting the form. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Re-enable the submit button
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent bg={colorMode === "dark" ? "gray.800" : "white"} color={colorMode === "dark" ? "white" : "black"}>
        <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold" color={colorMode === "dark" ? "white" : "primary.100"}>
        Demo
        </ModalHeader>
        <ModalCloseButton color={colorMode === "dark" ? "white" : "black"} />
        <ModalBody>
          <Flex direction={{ base: "column", md: "row" }} justify="space-between">
            <VStack spacing={4} flex="1" p={2}>
              <Input
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                bg={colorMode === "dark" ? "gray.700" : "white"}
                color={colorMode === "dark" ? "white" : "black"}
                _placeholder={{ color: colorMode === "dark" ? "gray.400" : "gray.600" }}
                style={!name && submitted ? { border: "1px solid red" } : {}}
              />
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                bg={colorMode === "dark" ? "gray.700" : "white"}
                color={colorMode === "dark" ? "white" : "black"}
                _placeholder={{ color: colorMode === "dark" ? "gray.400" : "gray.600" }}
                style={!email && submitted ? { border: "1px solid red" } : {}}
              />
              <Input
                placeholder="Contact Number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                type="number"
                min="0"
                step="1"
                bg={colorMode === "dark" ? "gray.700" : "white"}
                color={colorMode === "dark" ? "white" : "black"}
                _placeholder={{ color: colorMode === "dark" ? "gray.400" : "gray.600" }}
                style={!contactNumber && submitted ? { border: "1px solid red" } : {}}
              />
              <Button
                colorScheme={colorMode === "dark" ? "teal" : "green"}
                onClick={handleSubmit}
                width="100%"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            </VStack>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center"></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReqDemoModal;
