import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  extendTheme,
  Textarea,
  VStack,
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input,
  Stack,
  useToast,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  useColorModeValue,
  Flex,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { FaComments, FaTimes } from "react-icons/fa";
import $ from "jquery";
import axios from "axios";
import ReqDemoModal from "../components/reqDemo";
import api from "../config/axios";

// Define custom Chakra UI theme
const theme = extendTheme({
  fonts: {
    body: "Arial, sans-serif",
  },
});

const ChatBot = () => {
  const chatRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [jobDetails, setJobDetails] = useState("");
  const [recruiterDetails, setRecruiterDetails] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [query, setQuery] = useState([]); // Added query state
  const [isSubmitted, setIsSubmitted] = useState(false);
  const toast = useToast();
  const [isReqDemoModalOpen, setIsReqDemoModalOpen] = useState(false);

  useEffect(() => {
    // Initialize jQuery code here
    $(chatRef.current)
      .find("#submit")
      .click(function () {
        const question = $(chatRef.current).find("#question").val();
        handleQuestion(question);
      });
  }, []);

  const handleQuestion = (question) => {
    // Disable the input field and submit button
    setInputDisabled(true);

    // Sample question-answer pairs
    const qaPairs = [
      {
        question: "What is your name?",
        answer: "My name is ChatBot. How can I assist you?",
      },
      {
        question: "What services do you offer?",
        answer:
          "I can provide information about our products, assist with account-related queries, and help with troubleshooting.",
      },
      {
        question: "How can I contact customer support?",
        answer:
          "You can reach our customer support team by calling our toll-free number or sending an email to support@example.com.",
      },
      {
        question: "Do you offer refunds?",
        answer:
          "Yes, we have a refund policy in place. Please provide more details about your purchase so that I can assist you further.",
      },
      {
        question: "Can you help with a technical issue?",
        answer:
          "Certainly! Please describe the technical issue you are facing, and I will do my best to assist you.",
      },
    ];

    // Check if the question matches any predefined question-answer pair
    const matchingPair = qaPairs.find(
      (pair) => pair.question.toLowerCase() === question.toLowerCase()
    );

    // Prepare the response
    let response;
    if (matchingPair) {
      response = matchingPair.answer;
    } else {
      response =
        "I'm sorry, but I couldn't understand your question. Please try asking something else.";
    }

    // Add the question and response to the chat history
    const updatedHistory = [
      ...chatHistory,
      { type: "user", content: question },
      { type: "bot", content: response },
    ];
    setChatHistory(updatedHistory);

    // Clear the input field
    $(chatRef.current).find("#question").val("");

    // Enable the input field after a delay to simulate bot response time
    setTimeout(() => {
      setInputDisabled(false);
    }, 1000);
  };

  const toggleChat = () => {
    setChatOpen((prevOpen) => !prevOpen);
  };

  const handleReqDemoModalOpen = () => {
    setIsReqDemoModalOpen(true);
  };

  const handleReqDemoModalClose = () => {
    setIsReqDemoModalOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // If query is an array, join it into a single string
    const queryString = Array.isArray(query) ? query.join(", ") : query;

    const formData = {
      name: name,
      email: email,
      mobile_no: phone,
      message: message,
    };

    try {
      // Call the submit_pricing_inquiry API using axios
      const response = await api.post("/submit_pricing_inquiry", formData);

      if (response.status === 200) {
        setIsSubmitted(true);
        
        // Show success toast for the form submission
        toast({
          title: "Submitted Successfully!",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom",
        });

        // Now, trigger the email sending after successful query submission
        const emailPayload = { email };
        const emailResponse = await api.post(
          "/send_customer_support_email",
          emailPayload
        );

        if (emailResponse.status === 200) {
          // Show success toast for email sending
          toast({
            title: "Email sent successfully!",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "bottom",
          });
        } else {
          // Show an error toast if email sending fails
          toast({
            title: "Error sending email.",
            description: "There was an issue while sending the support email.",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "bottom",
          });
        }

        // Reset form state after both submissions
        setName("");
        setEmail("");
        setPhone("");
        setQuery([]); // Reset query if needed
        setMessage("");
      } else {
        // Show error toast if customer service query submission fails
        toast({
          title: "Error submitting inquiry.",
          description: "There was an issue while submitting your request.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // Show a general error toast if something goes wrong
      toast({
        title: "An error occurred.",
        description: "Something went wrong, please try again later.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "bottom",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box position="fixed" bottom="20px" right="20px" zIndex={9999}>
      {/* Chat icon */}
      <Button
        size="md"
        color="white"
        bg="#3B58D7"
        leftIcon={chatOpen ? <FaTimes /> : <FaComments />}
        onClick={toggleChat}
        marginBottom={2}
      >
        {chatOpen ? "Close" : "Chat"}
      </Button>

      {chatOpen && (
        <Box
          ref={chatRef}
          width="330px"
          maxHeight="80vh"
          overflowY="auto"
          border="1px solid #ccc"
          borderRadius="md"
          boxShadow="md"
          padding={3}
          position="fixed"
          bottom="70px"
          right="20px"
          bg={useColorModeValue("white", "gray.800")}
        >
          {/* Chatbot header with close button */}
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Box fontWeight="bold" fontSize="lg">
              Aptofy Chatbot
            </Box>
            <IconButton
              icon={<FaTimes />}
              size="sm"
              variant="ghost"
              aria-label="Close chat"
              onClick={toggleChat}
            />
          </Flex>
          <Divider mb={3} />
          
          <Text fontSize="sm" mb={3}>
            Hi! Let us know how we can help and we'll respond shortly.
          </Text>

          {/* Chat history */}
          <VStack
            spacing={2}
            align="flex-start"
            maxHeight="200px"
            overflowY="auto"
            mb={3}
          >
            {chatHistory.map((chat, index) => (
              <Box
                key={index}
                p={2}
                borderRadius="md"
                backgroundColor={chat.type === "user" ? "gray.100" : "blue.100"}
                alignSelf={chat.type === "user" ? "flex-start" : "flex-end"}
              >
                {chat.content}
              </Box>
            ))}
          </VStack>

          {/* Form */}
          <form onSubmit={handleFormSubmit}>
            <VStack spacing={3} align="flex-start">
              {/* Added Name field */}
              <FormControl size="sm">
                <FormLabel fontSize="sm">Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  required
                  size="sm"
                />
              </FormControl>
              <FormControl size="sm">
                <FormLabel fontSize="sm">Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  size="sm"
                />
              </FormControl>
              <FormControl size="sm">
                <FormLabel fontSize="sm">Phone</FormLabel>
                <Input
                  type="tel"
                  name="phone"
                  maxLength={10}
                  minLength={10}
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number"
                  size="sm"
                />
              </FormControl>
              <FormControl size="sm">
                <FormLabel fontSize="sm" mb={1}>Services</FormLabel>
                <CheckboxGroup
                  colorScheme="blue"
                  value={query}
                  onChange={setQuery}
                >
                  <Stack spacing={1} direction="column">
                    <Checkbox value="Expert Interview Anytime, Anywhere" size="sm">
                      <Text fontSize="sm">Expert Interview Anytime, Anywhere</Text>
                    </Checkbox>
                    <Checkbox value="Effortless, Smart Recruiting" size="sm">
                      <Text fontSize="sm">Effortless, Smart Recruiting</Text>
                    </Checkbox>
                    <Checkbox value="Build a High-Impact Team" size="sm">
                      <Text fontSize="sm">Build a High-Impact Team</Text>
                    </Checkbox>
                    <Checkbox value="Prepare Candidates for Success" size="sm">
                      <Text fontSize="sm">Prepare Candidates for Success</Text>
                    </Checkbox>
                    <Checkbox value="Reliable Remote Certification You Can Trust" size="sm">
                      <Text fontSize="sm">Reliable Remote Certification</Text>
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              <FormControl size="sm">
                <FormLabel fontSize="sm">Your Message</FormLabel>
                <Textarea
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message"
                  size="sm"
                  rows={3}
                />
              </FormControl>

              <Flex width="100%" justifyContent="space-between" mt={2}>
                <Button
                  type="submit"
                  color="white"
                  bg="#3B58D7"
                  isLoading={isLoading}
                  size="sm"
                  flex="1"
                  mr={2}
                >
                  Submit
                </Button>
                <Button
                  bg="#3B58D7"
                  color="white"
                  onClick={handleReqDemoModalOpen}
                  size="sm"
                  flex="1"
                >
                  See Demo
                </Button>
              </Flex>
            </VStack>
          </form>
        </Box>
      )}

      {/* Request demo modal */}
      <ReqDemoModal
        isOpen={isReqDemoModalOpen}
        onClose={handleReqDemoModalClose}
      />
    </Box>
  );
};

export default ChatBot;